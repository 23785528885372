
import { useEffect, useRef, useState } from "react";
import Header from "./Header";
import { Link, useNavigate } from "react-router-dom";
import { mapUtil } from "../util/mapUtil";
import axios from "axios";
function Mypage (props){
    const [editInfo,setEditInfo] = useState('');
    const [warningTxt,setWarningTxt] = useState('');
    const emailRegExp = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    const phRegExp = /^01(?:0|1|[6-9])-(?:\d{3}|\d{4})-\d{4}$/;
    const pwRegExp = /^[a-zA-z0-9]{8,20}$/;
    const navigate = useNavigate;
    function occupationToKorean(data){
        let message
        if(data === 'null'){message = '입력안함'}
        if(data === 'prospectiveBuilder'){message = '예비 건축주'}
        if(data === 'constructionWorker'){message = '건축업 종사자'}
        return message
    }
    function checkValue(RegExp,value,setUseState,message){
        if(!RegExp.test(value)){
            setUseState(message);
        }else{
            setUseState('');
        }
    }
    function submitMypage(dataName){
        const form = document.mypage_edit;
        let changeValue;
        if(dataName === "birth"){
            let year = form.birthYear.value.toString().padStart(4,'0');
            let month = form.birthMonth.value.toString().padStart(2,'0');
            let day = form.birthDay.value.toString().padStart(2,'0');
            const birth = `${year}-${month}-${day}`
            changeValue = birth
        }else{
            changeValue = form[dataName].value;
        }
        axios({
            method: 'patch',
            url: `${process.env.REACT_APP_BACKEND_SERVER}/user/updateInfo`,
            data: {
                id : props.userInfo.id,
                provider : props.userInfo.provider,
                changeDataName : dataName,
                changeValue : changeValue,

            },
            withCredentials: true
        }).then((response) => {
            console.log(response);
            props.checkLogin();
            setEditInfo('');
        }).catch((error) => {
            console.log(error.response.data);
            if(error.response.status===401){
                alert('로그인 후 이용가능합니다.');
                navigate(-1);
            }
        });
    }
    
    function pwChange(e){
        if(e.target.value.length >= 13){
            e.target.value = e.target.value.slice(0,13);
        }
        e.target.value = e.target.value
        .replace(/[^0-9]/g, '')
        .replace(/^(\d{0,3})(\d{0,4})(\d{0,4})$/g, "$1-$2-$3").replace(/(\-{1,2})$/g, "");
    }

    function birthChangeHandler(){
        const form = document.mypage_edit;
        if(form.birthYear.value.length > 4){
            form.birthYear.value = form.birthYear.value.slice(0,4);
        }
        if(form.birthDay.value.length > 2){
            form.birthDay.value = form.birthDay.value.slice(0,2);
        }
        if(form.birthYear.value === '' || form.birthMonth.value === "null" || form.birthDay.value === ''){return false}        
        setWarningTxt('');
    }

    function occuChangeHandler(){
        const form = document.mypage_edit;
        if(form.occu.value === "null"){
            setWarningTxt('직업을 선택해 주세요');
        }else{
            setWarningTxt('');
        }
    }


    useEffect(()=>{
        props.checkLogin();
    },[]);
    return (
        <div id="mypage">
            <Header userInfo={props.userInfo} logout={props.logout}/>
            <main>
                {props.userInfo === null || props.bookMarks === null ? '' : 
                    <div className="container">
                        <div className="my_wrap wrap">
                            <form name="mypage_edit">
                            <h2>내 정보</h2>
                            <ul className="my_info">
                                {editInfo ==='email'? 
                                    <>
                                    <li>
                                        <p><span>Email</span></p>
                                        <p className="input_box_shadow"><input className="edit_empty" type="text" placeholder={props.userInfo.email} name="email" onChange={(e)=>{checkValue(emailRegExp,e.target.value,setWarningTxt,'이메일 형식으로 입력해주세요.');}}/>
                                        </p>                                        
                                    </li>
                                    <div className="btn_box info_btn">
                                        <p className="warning_txt">{warningTxt}</p>
                                        <a className={`submit_btn my_submit_btn ${warningTxt !== '' ? "disabled" : ""}`} onClick={()=>{submitMypage('email')}}>수정완료</a>
                                        <a className="submit_btn my_submit_btn" onClick={()=>{setEditInfo('')}}>취소</a>                                    
                                    </div>
                                    </>:
                                    <li>
                                        <p><span>Email</span></p>
                                        <p>{props.userInfo.email}
                                            <button type="button" className="info_change" onClick={()=>{setEditInfo('email');setWarningTxt('이메일을 입력해 주세요.');}}><img src={process.env.PUBLIC_URL+"images/map/image_nick_edit.svg"} alt="닉네임변경" className="nick_img"/></button>
                                        </p>
                                    </li>
                                }
                                {editInfo ==='phone'? 
                                    <>
                                    <li>
                                        <p><span>Phone</span></p>
                                        <p className="input_box_shadow"><input className="edit_empty" type="text" placeholder={props.userInfo.ph} name="ph" onInput={(e)=>{pwChange(e)}} onChange={(e)=>{checkValue(phRegExp,e.target.value,setWarningTxt,'전화번호를 입력해주세요.');}}/>
                                        </p>                                        
                                    </li>
                                    <div className="btn_box info_btn">
                                        <p className="warning_txt">{warningTxt}</p>
                                        <a className={`submit_btn my_submit_btn ${warningTxt !== '' ? "disabled" : ""}`} onClick={()=>{submitMypage('ph')}}>수정완료</a>
                                        <a className="submit_btn my_submit_btn" onClick={()=>{setEditInfo('')}}>취소</a>
                                    </div>
                                    </>:
                                    <li>
                                        <p><span>Phone</span></p>
                                        <p>{props.userInfo.ph === "" ? "입력안함" : props.userInfo.ph}                                        
                                            <button type="button" className="info_change" onClick={()=>{setEditInfo('phone');setWarningTxt('핸드폰 번호를 입력해 주세요.');}}><img src={process.env.PUBLIC_URL+"images/map/image_nick_edit.svg"} alt="닉네임변경" className="nick_img"/></button>
                                        </p>
                                    </li>                                 
                                }                                
                                {editInfo ==='name'? 
                                    <>
                                    <li>
                                        <p><span>Name / Company</span></p>
                                        <p className="input_box_shadow"><input className="edit_empty" type="text" placeholder={props.userInfo.name} name="name" onChange={(e)=>{if(e.target.value){setWarningTxt('')}}}/>
                                        </p>
                                        
                                    </li>
                                    <div className="btn_box info_btn">
                                        <p className="warning_txt">{warningTxt}</p>
                                        <a className={`submit_btn my_submit_btn ${warningTxt !== '' ? "disabled" : ""}`} onClick={()=>{submitMypage('name')}}>수정완료</a>
                                        <a className="submit_btn my_submit_btn" onClick={()=>{setEditInfo('')}}>취소</a>
                                    </div>
                                    </>:
                                    <li>
                                        <p><span>Name / Company</span></p>
                                        <p>{props.userInfo.name === "" ? "입력안함" : props.userInfo.name}                                        
                                            <button type="button" className="info_change" onClick={()=>{setEditInfo('name');setWarningTxt('이름을 입력해 주세요.');}}><img src={process.env.PUBLIC_URL+"images/map/image_nick_edit.svg"} alt="닉네임변경" className="nick_img"/></button>
                                        </p>
                                    </li>                                 
                                }
                                {editInfo ==='gender'? 
                                    <>
                                    <li>
                                        <p>Gender</p>
                                        <div className="edit_select">
                                            <input type="radio" id="select1" name="gender" value="male" onClick={()=>{setWarningTxt('')}}/>
                                            <label htmlFor="select1">남자</label>
                                            <input type="radio" id="select2" name="gender" value="female" onClick={()=>{setWarningTxt('')}}/>
                                            <label htmlFor="select2">여자</label>
                                        </div>                               
                                    </li>
                                    <div className="btn_box info_btn">
                                        <p className="warning_txt">{warningTxt}</p>
                                        <a className={`submit_btn my_submit_btn ${warningTxt !== '' ? "disabled" : ""}`} onClick={()=>{submitMypage('gender')}}>수정완료</a>
                                        <a className="submit_btn my_submit_btn" onClick={()=>{setEditInfo('')}}>취소</a>
                                    </div>
                                    </>:
                                    <li>
                                        <p><span>Gender</span></p>
                                        <p>{props.userInfo.gender === "" ? "입력안함" : props.userInfo.gender === 'female' ? '여자' : '남자'}                                        
                                            <button type="button" className="info_change" onClick={()=>{setEditInfo('gender');setWarningTxt('성별을 선택해 주세요.');}}><img src={process.env.PUBLIC_URL+"images/map/image_nick_edit.svg"} alt="닉네임변경" className="nick_img"/></button>
                                        </p>
                                    </li>                                 
                                }
                                {editInfo ==='birth'? 
                                    <>
                                    <li className="select_form">
                                        <p>Birthday</p>
                                        <div className="edit_select">
                                            <p className="input_box_shadow"><input type="number" placeholder="년(4자)" name="birthYear" onChange={birthChangeHandler}/></p>
                                            <select name="birthMonth" onChange={birthChangeHandler}>
                                                <option value="null" className="month">--월</option>
                                                <option value="1">1월</option>
                                                <option value="2">2월</option>
                                                <option value="3">3월</option>
                                                <option value="4">4월</option>
                                                <option value="5">5월</option>
                                                <option value="6">6월</option>
                                                <option value="7">7월</option>
                                                <option value="8">8월</option>
                                                <option value="9">9월</option>
                                                <option value="10">10월</option>
                                                <option value="11">11월</option>
                                                <option value="12">12월</option>
                                            </select>
                                            <p className="input_box_shadow"><input type="number" placeholder="일" name="birthDay" onChange={birthChangeHandler}/></p>
                                        </div>
                                    </li>
                                    <div className="btn_box info_btn">
                                        <p className="warning_txt">{warningTxt}</p>
                                        <a className={`submit_btn my_submit_btn ${warningTxt !== '' ? "disabled" : ""}`} onClick={()=>{submitMypage('birth')}}>수정완료</a>
                                        <a className="submit_btn my_submit_btn" onClick={()=>{setEditInfo('')}}>취소</a>
                                    </div>
                                    </>:
                                    <li>
                                        <p><span>Birthday</span></p>
                                        <p>{props.userInfo.birth === "0000-null-00" ? "입력안함" : props.userInfo.birth}                                       
                                            <button type="button" className="info_change" onClick={()=>{setEditInfo('birth');setWarningTxt('생년월일을 입력해 주세요.');}}><img src={process.env.PUBLIC_URL+"images/map/image_nick_edit.svg"} alt="닉네임변경" className="nick_img"/></button>
                                        </p>
                                    </li>
                                }
                                {editInfo ==='occu'?
                                    <>
                                    <li className="select_form">
                                        <p>Occupational</p>
                                        <div className="edit_occu_select">
                                            <select onChange={occuChangeHandler} name="occu">
                                                <option value="null">직업 선택</option>
                                                <option value="prospectiveBuilder">예비 건축주</option>
                                                <option value="constructionWorker">건축업 종사자</option>
                                            </select>
                                        </div>
                                    </li>
                                    <div className="btn_box info_btn">
                                        <p className="warning_txt">{warningTxt}</p>
                                        <a className={`submit_btn my_submit_btn ${warningTxt !== '' ? "disabled" : ""}`} onClick={()=>{submitMypage('occu')}}>수정완료</a>
                                        <a className="submit_btn my_submit_btn" onClick={()=>{setEditInfo('')}}>취소</a>
                                    </div>
                                    </>:
                                    <li>
                                        <p><span>Occupation</span></p>
                                        <p>
                                            {occupationToKorean(props.userInfo.occu)}
                                            <button type="button" className="info_change" onClick={()=>{setEditInfo('occu');setWarningTxt('직업을 선택해 주세요.');}}><img src={process.env.PUBLIC_URL+"images/map/image_nick_edit.svg"} alt="닉네임변경" className="nick_img"/></button>
                                        </p>
                                    </li>
                                }
                            </ul>
                            </form>
                        </div>
                        <form className="my_edit_wrap" action="#" method="get">
                            <fieldset>
                                <legend>내정보 수정 박스</legend>
                                <h2>정보 수정</h2>
                                <ul className="edit_box">
                                    <li className="select_form">
                                        <p>Occupational</p>
                                        <div className="edit_occu_select">
                                            <select>
                                                <option value="">예비 건축주</option>
                                                <option value="">건축업 종사자</option>
                                            </select>
                                        </div>
                                    </li>
                                </ul>
                                <div className="agree_wrap"> 
                                    <ul className="agree_select">                                        
                                        <li>
                                            <div className="clause">
                                                <input type="checkbox" id="gps" name="agree"/>
                                                <label htmlFor="gps">위치기반서비스 이용약관 동의</label>
                                            </div>
                                            <p><a href="#">자세히보기</a></p>
                                        </li>
                                        <li>
                                            <div className="clause">
                                                <input type="checkbox" id="promotion" name="agree"/>
                                                <label htmlFor="promotion">프로모션 정보 수신 동의</label>
                                            </div>
                                            <p><a href="#">자세히보기</a></p>
                                        </li>
                                    </ul>
                                </div>
                                <div className="privacy_box">
                                    <p className="privacy">개인정보 이용내역</p>
                                    <div className="privacy_link"><a href="#">조회</a></div>
                                </div>
                                <div className="btn_box"><a href="#" className="submit_btn my_submit_btn">수정완료</a></div>
                            </fieldset>
                        </form>
                        <MypageBookMarkList bookMarks={props.bookMarks} userInfo={props.userInfo} getBookMarks={props.getBookMarks}/>
                    </div>
                }
            </main>
            <div className="footer_bg">
                <footer>
                <ul className="footer_info">
                    <li><address>주소 | 서울시 관악구 문성로 199-1 2층</address></li>
                    <li>대표 | 고호준</li>
                    <li>개인정보 관리 책임자 | 이형우</li>
                    <li>사업자등록번호 | 469-81-03262</li>
                </ul>
                <ul className="footer_link">
                    <li><Link to={'/Company'}>회사소개</Link></li>
                    <li><Link to={'/agree'} state={{ index : 0}}>이용약관</Link></li>
                    <li><Link to={'/agree'} state={{ index : 1}}>개인정보처리방침</Link></li>
                    <li><Link to={'/agree'} state={{ index : 2}}>위치기반 서비스 이용약관</Link></li>
                </ul>
                <p>Copyright 2023 propmate Inc. all rights reserved.</p>
                </footer>
            </div>
        </div>
    );
}

function MypageBookMarkList(props){
    const bookmark_form = document.mypage_bookmark;
    const navigate = useNavigate();
    const [tabCount,setTabCount] = useState(0);
    const [nameEditState, setNameEditState] = useState({});
    const [bookmarkEditState,setBookmarkEditState] = useState(false);
    const [selectedOptions, setSelectedOptions] = useState([]);


    function nameEditStateToggle(index) {
        const updatedStates = {...nameEditState};
        updatedStates[index] = !updatedStates[index];
        setNameEditState(updatedStates);
      }
    function moveToMap(address){
        mapUtil.searchAddressToCoordinate(address);
        navigate('/map');
    }
    function nameEdit(list_index,index,address){
        const formName = `name_${list_index}${index}`;
        console.log(bookmark_form[formName].value);
        const data = {
            id : props.userInfo.id,
            provider : props.userInfo.provider,
            bookmark_id : address,
            landName: bookmark_form[formName].value,
        }
        axios({
            method: 'post',
            url: process.env.REACT_APP_BOOKMARKUPDATE_SERVER_URL,
            data,
            withCredentials: true 
        }).then((response) => {
            console.log(response);
            props.getBookMarks();
            nameEditStateToggle(`${list_index}${index}`);
        }).catch((error) => {
            console.log(error.response.data);
            if(error.response.status===401){
                alert('로그인 후 이용가능합니다.');
                navigate(-1);
            }
        });
    }
    function bookmarkEdit(){
        if(selectedOptions.length === 0){setBookmarkEditState(false); return false};
        let newBookMarkList = props.bookMarks.map((list_item) => {
                return {
                    ...list_item,
                    bookMarks: list_item.bookMarks.filter(bookmark => 
                        !selectedOptions.includes(bookmark.bookmark_id)
                    )
                };
            });
        axios({
            method: 'post',
            url: process.env.REACT_APP_BOOKMARKCHANGE_SERVER_URL,
            data: {
                _id : props.userInfo.id,
                provider : props.userInfo.provider,
                bookMarkList : newBookMarkList,
            },
            withCredentials: true 
        }).then((response) => {
            console.log(response);
            props.getBookMarks();
            setSelectedOptions([]);
        }).catch((error) => {
            console.log(error.response.data);
            if(error.response.status===401){
                alert('로그인 후 이용가능합니다.');
                navigate(-1);
            }
        });
    }

    const handleCheckboxChange = (e) => {
        const value = e.target.value;
        const checked = e.target.checked;
    
        if (checked) {
            setSelectedOptions([...selectedOptions, value]);
        } else {
            setSelectedOptions(selectedOptions.filter(option=> option !== value));
        }
    };

    useEffect(()=>{
        const newObject = {};
        props.bookMarks.map((list_item,list_index)=>{
            list_item.bookMarks.map((item,index)=>{
                newObject[`${list_index}${index}`] = false
            });
        });
        setNameEditState(newObject);
        console.log(newObject);
    },[]);

    useEffect(()=>{
    },[tabCount]);

    useEffect(()=>{
        console.log(nameEditState);
    },[nameEditState]);

    return(
        <form className="mypage_bookmark" name="mypage_bookmark">
            <div className="bookmark_wrap bm_wrap">
                <h2>북마크</h2>
                <div className="bm_contents">
                    <ul className="bm_group">                        
                        <li className='bm_list_btn' style={{width:100/(props.bookMarks.length+1)+'%'}}onClick={()=>{setTabCount(0)}}>
                            <div className="bm_list_btn">
                                <button type="button" className={`list_btn ${tabCount === 0? 'y':''}`}>전체</button>
                            </div>
                        </li>
                        {props.bookMarks.map((item,index)=>{
                            return(
                                <li className='bm_list_btn' style={{width:100/(props.bookMarks.length+1)+'%'}} key={index} onClick={()=>{setTabCount(index+1)}}>
                                    <div className="bm_list_btn">
                                        <button type="button" className={`list_btn ${tabCount === index + 1 ? 'y':''}`}>{item.bookMarkListName}</button>
                                    </div>
                                </li>
                            )
                        })}
                    </ul>                
                    <ul className="bm_list_all">
                        {tabCount === 0 ? 
                            <li className='bm_all active'>
                                <ul className="bm_list">
                                    {props.bookMarks.length === 0 ?
                                        <div className="bookmark_empty_message">
                                            지도에서 북마크를 등록해주세요!
                                        </div>
                                    :props.bookMarks.map((list_item,list_index)=>{
                                        if(list_item.bookMarks === undefined){return false}
                                        return list_item.bookMarks.map((item,index)=>{
                                            return(
                                                <li key={`${list_index}${index}`}>
                                                    <div className="bm_list_txt" style={{borderColor:list_item.bookMarkListColor}}>
                                                        {nameEditState[`${list_index}${index}`] === false ? 
                                                        
                                                            <div className="bm_nickname">
                                                                <span className="nick">{item.landName}</span>
                                                                {bookmarkEditState ===true ?
                                                                <button type="button" className="nick_change"  onClick={() => nameEditStateToggle(`${list_index}${index}`)}><img src={process.env.PUBLIC_URL+"images/map/image_nick_edit.svg"} alt="닉네임변경" className="nick_img"/></button>
                                                                :''
                                                                }
                                                            </div> :
        
                                                            <div className="bm_nickname_edit">
                                                                <p><input type="text" maxLength="8" name={`name_${list_index}${index}`} placeholder={item.landName}/></p>
                                                                <button type="button" className="nick_change_success" onClick={() => nameEdit(list_index,index,item.bookmark_id)}>
                                                                    <img src={process.env.PUBLIC_URL+"images/map/image_nick_check.png"} alt="닉네임변경완료버튼"/>
                                                                </button>
                                                            </div>
                                                        }
        
                                                        <div className="bm_address" style={item.landName === ''? {marginTop:0}:{}}><span>{item.bookmark_id}</span></div>
                                                        <div className="bm_space">
                                                            <span className="space_fix">대지면적  : </span>
                                                            <span> {item.plotAr}㎡ ({(item.plotAr*0.3025).toFixed(2)}평)</span>
                                                        </div>
                                                    </div>
                                                    {bookmarkEditState === true ?                                            
                                                        <div className="bm_check">
                                                            <input type="checkbox" value={item.bookmark_id} id={`ch${list_index}${index}`} name="bookmark_edit_check" onChange={handleCheckboxChange}/>
                                                            <label htmlFor={`ch${list_index}${index}`}></label>
                                                        </div>:                                                
                                                        <div className="bm_check">
                                                            <div onClick={()=>{moveToMap(item.bookmark_id)}}><img src={process.env.PUBLIC_URL+"images/map/image_map_point.svg"} alt="지도페이지로이동"/></div>
                                                        </div>
                                                    }
                                                </li>
                                            )
                                        })
                                    })}
                                </ul>
                            </li>                        
                        :""}
                        {props.bookMarks.map((list_item,list_index)=>{
                            if(tabCount === list_index+1){
                                return(
                                    <li className='bm_all active' key={list_index}>
                                        <ul className="bm_list">
                                            {props.bookMarks.length === 0 ? 
                                                <div className="bookmark_empty_message">
                                                    지도에서 북마크를 등록해주세요!
                                                </div>
                                                :list_item.bookMarks.map((item,index)=>{
                                                    return(
                                                        <li key={`${list_index}${index}`}>
                                                            <div className="bm_list_txt" style={{borderColor:list_item.bookMarkListColor}}>
                                                                {nameEditState[`${list_index}${index}`] === false ? 
                                                                
                                                                    <div className="bm_nickname">
                                                                        <span className="nick">{item.landName}</span>
                                                                        {bookmarkEditState ===true ?
                                                                        <button type="button" className="nick_change"  onClick={() => nameEditStateToggle(`${list_index}${index}`)}><img src={process.env.PUBLIC_URL+"images/map/image_nick_edit.svg"} alt="닉네임변경" className="nick_img"/></button>
                                                                        :''
                                                                        }
                                                                    </div> :
    
                                                                    <div className="bm_nickname_edit">
                                                                        <p><input type="text" maxLength="8" name={`name_${list_index}${index}`} placeholder={item.landName}/></p>
                                                                        <button type="button" className="nick_change_success" onClick={() => nameEdit(list_index,index,item.bookmark_id)}>
                                                                            <img src={process.env.PUBLIC_URL+"images/map/image_nick_check.png"} alt="닉네임변경완료버튼"/>
                                                                        </button>
                                                                    </div>
                                                                }
    
                                                                <div className="bm_address" style={item.landName === ''? {marginTop:0}:{}}><span>{item.bookmark_id}</span></div>
                                                                <div className="bm_space">
                                                                    <span className="space_fix">대지면적  : </span>
                                                                    <span> {item.plotAr}㎡ ({(item.plotAr*0.3025).toFixed(2)}평)</span>
                                                                </div>
                                                            </div>
                                                            {bookmarkEditState === true ?                                            
                                                                <div className="bm_check">
                                                                    <input type="checkbox"  value={item.bookmark_id} id={`ch${list_index}${index}`} name="bookmark_edit_check" onChange={handleCheckboxChange}/>
                                                                    <label htmlFor={`ch${list_index}${index}`}></label>
                                                                </div>:                                                
                                                                <div className="bm_check">
                                                                    <div onClick={()=>{moveToMap(item.bookmark_id)}}><img src={process.env.PUBLIC_URL+"images/map/image_map_point.svg"} alt="지도페이지로이동"/></div>
                                                                </div>
                                                            }
                                                        </li>
                                                    )
                                                })
                                            }
                                        </ul>
                                    </li>

                                )
                            }
                        })}
                    </ul>
                </div>
                <div className="btn_box bm_edit_btn">
                    {props.bookMarks.length === 0 ? <button className="submit_btn bm_btn map_btn" type="button" onClick={()=>{navigate('/map')}}>지도 바로가기</button> : 
                        bookmarkEditState === true && selectedOptions.length === 0? <button className="submit_btn bm_btn" type="button" onClick={()=>{setBookmarkEditState(!bookmarkEditState)}}>수정완료</button>:
                        bookmarkEditState === true && selectedOptions.length !== 0?
                        <button className="submit_btn bm_btn" type="button" onClick={bookmarkEdit}>삭제하기</button> :
                        
                        <button className="submit_btn bm_btn" type="button" onClick={()=>{setBookmarkEditState(!bookmarkEditState)}}>수정하기</button>
                    }
                
                </div>
            </div>
        </form>
    )
}

export default Mypage;