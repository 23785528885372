import { useEffect, useState } from "react";
import axios from "axios";
import { Link, useNavigate,useLocation } from "react-router-dom";
import Header from "./Header";

function Login(props){
    const [overlayMove,setOverlayMove] = useState('right');
    const [formFrontIndex,setFormFrontIndex] = useState(0);
    const [loginWarningTxt,setLoginWarningTxt] = useState('');
    const [returnUrl,setReturnUrl] = useState('');
    const navigate = useNavigate();
    const location = useLocation();
    
    useEffect(()=>{
        props.checkLogin();
        const url = window.location.href;
        if(location.state === null){
            setReturnUrl(url.substring(0, url.lastIndexOf('/')));
        }else{
            setReturnUrl(location.state.returnUrl);
        }
    },[]);
      
    function login(){
        const form = document.loginForm;
        if(!form.id.value || !form.pw.value){
            setLoginWarningTxt('아이디 와 비밀번호를 모두 입력해주세요.');
            if(!form.id.value){
                form.id.focus();
            }else{
                form.pw.focus();
            }
            return false;
        }
        axios({
            method: 'post',
            url: `${process.env.REACT_APP_BACKEND_SERVER}/login`,
            data: {
                id: form.id.value,
                pw: form.pw.value,
            },
            withCredentials: true
        }).then((response) => {    
            setLoginWarningTxt('');
            navigate(-1);
        }).catch((error) => {
            console.log(error);
            console.log(error.response.data);
            setLoginWarningTxt(error.response.data);
        });
    }

    return(
        <div id="loginPage">  
            <Header userInfo={null} logout={props.logout}/>
                <main>
                    <div className={`wrap ${overlayMove == 'left' ? 'active' :''}`}>
                        <form id="login_container" action="#" method="get" name="loginForm">
                            <fieldset>
                                <legend>로그인박스</legend>
                                <ul className="login_wrap">
                                <li className="login_box id_box">
                                    <p className="login_txt">ID</p>
                                    <a className="find" onClick={()=>{setOverlayMove('left');setFormFrontIndex(1);}}>아이디 찾기</a>
                                    <input className="login_id_empty"  type="text" placeholder="프롭메이트 아이디 입력" name="id" onKeyDown={(e)=>{if(e.keyCode === 13){login()}}}/>
                                </li>
                                <li className="login_box pw_box">
                                    
                                    <p className="login_txt">Password</p>
                                    <a className="find" onClick={()=>{setOverlayMove('left');setFormFrontIndex(2);}}>비밀번호 재설정</a>
                                    <input className="login_pw_empty" type="password" placeholder="프롭메이트 비밀번호 입력" name="pw" onKeyDown={(e)=>{if(e.keyCode === 13){login()}}}/>
                                </li>
                                <li>
                                    <span className="login_warning_txt">{loginWarningTxt}</span>
                                </li>
                                <li>
                                    <ul className="login_btn_box">
                                        <li className="kakao_btn" id="kakao-login-btn">
                                            <a href={`${process.env.REACT_APP_KAKAO_SERVER_URL}?returnUrl=${returnUrl}`}><img src="images/kakao_login.png" alt="카카오로그인버튼" title="카카오로그인" /></a>
                                        </li>
                                        <li className="naver_btn">
                                            <a className="naver_real_btn" href={`${process.env.REACT_APP_NAVER_SERVER_URL}?returnUrl=${returnUrl}`}><img src="images/naver_login.png" alt="네이버로그인버튼" title="네이버로그인" /></a>
                                        </li>
                                        <li className="login_btn" onClick={login}><a>로그인</a></li>
                                    </ul>
                                </li>
                                </ul>
                            </fieldset>
                        </form>
                        <JoinForm formFrontIndex={formFrontIndex} setOverlayMove={setOverlayMove} navigate={navigate}/>
                        <FindIdForm formFrontIndex={formFrontIndex} setOverlayMove={setOverlayMove}/>
                        <FindPwForm formFrontIndex={formFrontIndex} setOverlayMove={setOverlayMove}/>
                        <div className="overlay_back overlay"></div>
                        <div className="overlay_right overlay">
                            <h2>환영합니다!</h2>
                            <p>집을 짓기 위한 새로운 여정,<br/>프롭메이트와 함께 하세요!</p>
                            <div className="overlay_btn_box">
                                <button className="overlay_btn cover_join_btn join" onClick={()=>{setOverlayMove('left');setFormFrontIndex(0);}}>회원가입</button>
                            </div>
                        </div>
                        <div className="overlay_left overlay">
                            <h2>환영합니다!</h2>
                            <p>집을 짓기 위한 새로운 여정,<br/>프롭메이트와 함께 하세요!</p>
                            <div className="overlay_btn_box">
                                <button className="overlay_btn cover_join_btn" onClick={()=>{setOverlayMove('right');}}>로그인</button>
                            </div>
                        </div>
                    </div>
                </main>
                <div className="footer_bg">
                    <footer>
                        <ul className="footer_info">
                            <li><address>주소 | 서울시 관악구 문성로 199-1 2층</address></li>
                            <li>대표 | 고호준</li>
                            <li>개인정보 관리 책임자 | 이형우</li>
                            <li>사업자등록번호 | 469-81-03262</li>
                        </ul>
                        <ul className="footer_link">
                            <li><Link to={'/Company'}>회사소개</Link></li>
                            <li><Link to={'/agree'} state={{ index : 0}}>이용약관</Link></li>
                            <li><Link to={'/agree'} state={{ index : 1}}>개인정보처리방침</Link></li>
                            <li><Link to={'/agree'} state={{ index : 2}}>위치기반 서비스 이용약관</Link></li>
                        </ul>
                        <p>Copyright 2023 propmate Inc. all rights reserved.</p>
                    </footer>
                </div>
            </div>
    )    
}





function JoinForm (props) {
    const [joinWarningTxt,setJoinWarningTxt] = useState('');
    const [idWarningTxt,setIdWarningTxt] = useState('');
    const [pwWarningTxt,setPwWarningTxt] = useState('');
    const [phWarningTxt,setPhWarningTxt] = useState('');
    const [nameWarningTxt,setNameWarningTxt] = useState('');
    const [emailWarningTxt,setEmailWarningTxt] = useState('');
    const [joinBtnState,setJoinBtnState] = useState('disabled');
    const idRegExp = /^[a-zA-z0-9]{4,12}$/;
    const pwRegExp = /^[a-zA-z0-9]{8,20}$/;
    const phRegExp = /^01(?:0|1|[6-9])-(?:\d{3}|\d{4})-\d{4}$/;
    const emailRegExp = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

    function checkValue(RegExp,value,setUseState,message){
        if(!RegExp.test(value)){
            setUseState(message);
        }else{
            setUseState('');
        }
    }
    function onChangeAgreeAll(){
        const form = document.joinForm;
        console.log(form.agree_play.checked,form.agree_secret.checked,form.agree_gps.checked,form.agree_promotion.checked);
        if(!form.agree_play.checked || !form.agree_secret.checked || !form.agree_gps.checked || !form.agree_promotion.checked){
            form.agree_play.checked = true;
            form.agree_secret.checked = true;
            form.agree_gps.checked = true;
            form.agree_promotion.checked = true;
            form.agree_all.checked = true;
        }else{
            form.agree_play.checked = false;
            form.agree_secret.checked = false;
            form.agree_gps.checked = false;
            form.agree_promotion.checked = false;
            form.agree_all.checked = false;
        }
        
        if(form.agree_play.checked && form.agree_secret.checked){
            setJoinBtnState('');
        }else{
            setJoinBtnState('disabled');
        }
    }

    function onChangeAgree(){
        const form = document.joinForm;
        if(!form.agree_play.checked || !form.agree_secret.checked || !form.agree_gps.checked || !form.agree_promotion.checked){
            form.agree_all.checked = false;
        }else if(form.agree_play.checked && form.agree_secret.checked && form.agree_gps.checked && form.agree_promotion.checked){
            form.agree_all.checked = true;
        }
        if(form.agree_play.checked && form.agree_secret.checked){
            setJoinBtnState('');
        }else{
            setJoinBtnState('disabled');
        }
    }

    function pwChange(e){
        if(e.target.value.length >= 13){
            e.target.value = e.target.value.slice(0,13);
        }
        e.target.value = e.target.value
        .replace(/[^0-9]/g, '')
        .replace(/^(\d{0,3})(\d{0,4})(\d{0,4})$/g, "$1-$2-$3").replace(/(\-{1,2})$/g, "");
    }
    function join(){
        setJoinWarningTxt('');
        const form = document.joinForm;
        let year = form.birthYear.value.toString().padStart(4,'0');
        let month = form.birthMonth.value.toString().padStart(2,'0');
        let day = form.birthDay.value.toString().padStart(2,'0');
        const birth = `${year}-${month}-${day}`
        if(!form._id.value || !form.pw.value || !form.ph.value || !form.name.value || !form.email.value){
            if(!form.email.value){
                form.email.focus()
                setEmailWarningTxt('이메일을 입력해주세요.');
            }
            if(!form.name.value){
                form.name.focus()
                setNameWarningTxt('이름(기업명)을 입력해주세요.');
            }
            if(!form.ph.value){
                form.ph.focus()
                setPhWarningTxt('전화번호를 입력해주세요.');
            }
            if(!form.pw.value){
                form.pw.focus()
                setPwWarningTxt('비밀번호를 입력해주세요.');
            }
            if(!form._id.value){
                form._id.focus()
                setIdWarningTxt('아이디를 입력해주세요.');
            }
            return false;
        }
        if(joinWarningTxt !== '' || idWarningTxt !== '' || pwWarningTxt !== '' || phWarningTxt !== '' || nameWarningTxt !== '' || emailWarningTxt !== ''){
            if(emailWarningTxt !== ''){
                form.email.focus()
            }
            if(nameWarningTxt !== ''){
                form.name.focus()
            }
            if(phWarningTxt !== ''){
                form.ph.focus()
            }
            if(pwWarningTxt !== ''){
                form.pw.focus()
            }
            if(idWarningTxt !== ''){
                form._id.focus()
            }
            return false;
        }
        axios({
            method: 'post',
            url: `${process.env.REACT_APP_BACKEND_SERVER}/user/join`,
            data: {
                _id: form._id.value,
                pw: form.pw.value,
                ph: form.ph.value,
                name: form.name.value,
                email: form.email.value,
                gender: form.gender.value,
                birth: birth,
                occu: form.occu.value,
                agree: {
                    agree_play : form.agree_play.checked,
                    agree_secret : form.agree_secret.checked,
                    agree_gps : form.agree_gps.checked,
                    agree_promotion : form.agree_promotion.checked,                    
                },
                userLevel: 'user',
                provider: 'local'
            }
        }).then((response) => {
            console.log(response.data); 
            alert('회원가입이 완료되었습니다.');
            props.setOverlayMove('right');

        }).catch((error) => {
            console.log(error);
            setJoinWarningTxt(error.response.data.error);
        });

    }


    return(
        <form id="join_wrap" action="#" method="get" className={props.formFrontIndex == 0 ? "front" : ''} name="joinForm">
            <fieldset>
                <legend>회원가입박스</legend>
                <ul className="join_box">
                <li>
                    <p className="login_txt">ID</p><span className="warning_txt">{idWarningTxt}</span>
                    <p><input className="join_id_empty" type="text" placeholder="아이디 입력" name="_id" onChange={(e)=>{checkValue(idRegExp,e.target.value,setIdWarningTxt,'영문 대소문자와 숫자 4~12자리');}}/></p>
                </li>
                <li>
                    <p className="login_txt">Password</p><span className="warning_txt">{pwWarningTxt}</span>
                    <p><input className="join_pw_empty" type="password" placeholder="비밀번호 입력" name="pw" onChange={(e)=>{checkValue(pwRegExp,e.target.value,setPwWarningTxt,'영문 대소문자와 숫자 8~20자리');if(pwRegExp.test(e.target.value) && document.joinForm.pwck.value !== e.target.value){setPwWarningTxt('비밀번호 확인란을 입력해주세요.')}}}/></p>
                    <p><input className="join_pwck_empty" type="password" placeholder="비밀번호 확인" name="pwck" onChange={(e)=>{if(document.joinForm.pw.value !== e.target.value){setPwWarningTxt('비밀번호가 일치하지 않습니다.')}else if(document.joinForm.pw.value == e.target.value){setPwWarningTxt('')}}}/></p>
                </li>
                <li>
                    <p className="login_txt">Phone Number</p><span className="warning_txt">{phWarningTxt}</span>
                    <p><input className="join_phone_empty" type="text" placeholder="전화번호 입력 (숫자만)" name="ph" onInput={(e)=>{pwChange(e)}} onChange={(e)=>{checkValue(phRegExp,e.target.value,setPhWarningTxt,'전화번호를 입력해주세요.');}}/></p>
                </li>
                <li>
                    <p className="login_txt">Name/Company</p><span className="warning_txt">{nameWarningTxt}</span>
                    <p><input className="join_name_empty" type="text" placeholder="이름(기업명) 입력" name="name" onChange={(e)=>{if(e.target.value){setNameWarningTxt('')}}}/></p>
                </li>
                <li>
                    <p className="login_txt">Email</p><span className="warning_txt">{emailWarningTxt}</span>
                    <p><input className="join_mail_empty" type="text" placeholder="이메일 입력" name="email" onChange={(e)=>{checkValue(emailRegExp,e.target.value,setEmailWarningTxt,'이메일 형식으로 입력해주세요.');}}/></p>
                </li>
                <li className="select_form">
                    <p>Gender<span>선택</span></p>
                    <div className="join_select">
                    <input type="radio" id="select1" name="gender" value="male"/>
                    <label htmlFor="select1">남자</label>
                    <input type="radio" id="select2" name="gender" value="female"/>
                    <label htmlFor="select2">여자</label>
                    </div>
                </li>
                <li className="select_form">
                    <p>Birthday<span>선택</span></p>
                    <div className="join_select">
                        <input type="number" placeholder="년(4자)" name="birthYear"/>  
                        <select name="birthMonth">
                            <option value="null" className="month">--월</option>
                            <option value="1">1월</option>
                            <option value="2">2월</option>
                            <option value="3">3월</option>
                            <option value="4">4월</option>
                            <option value="5">5월</option>
                            <option value="6">6월</option>
                            <option value="7">7월</option>
                            <option value="8">8월</option>
                            <option value="9">9월</option>
                            <option value="10">10월</option>
                            <option value="11">11월</option>
                            <option value="12">12월</option>
                        </select>
                        <input type="number" placeholder="일" name="birthDay"/>
                    </div>
                </li>
                <li className="select_form">
                    <p>Occupational<span>선택</span></p>
                    <div className="join_occu_select">
                    <select name="occu">
                        <option value="null">직업 선택</option>
                        <option value="prospectiveBuilder">예비 건축주</option>
                        <option value="constructionWorker">건축업 종사자</option>
                    </select>
                    </div>
                </li>
                </ul>
                <div className="agree_wrap">
                    <p>
                        <input type="checkbox" name="agree_all" id="agree_all" onChange={onChangeAgreeAll}/>
                        <label htmlFor="agree_all">프롭메이트 정보수집에 모두 동의합니다.</label>
                    </p>
                    <ul className="agree_select">
                        <li>
                            <div className="clause">
                                <input type="checkbox" id="play" name="agree_play" onChange={onChangeAgree}/>
                                <label htmlFor="play">프롭메이트 이용약관 동의<span>필수</span></label>
                            </div>
                            <p><Link to='/agree' state={{ index : 0}}>자세히보기</Link></p>
                        </li>
                        <li>
                            <div className="clause">
                                <input type="checkbox" id="secret" name="agree_secret" onChange={onChangeAgree}/>
                                <label htmlFor="secret">개인정보 수집 및 이용 동의<span>필수</span></label>
                            </div>
                            <p><Link to='/agree' state={{ index : 1}}>자세히보기</Link></p>
                        </li>
                        <li>
                            <div className="clause">
                                <input type="checkbox" id="gps" name="agree_gps" onChange={onChangeAgree}/>
                                <label htmlFor="gps">위치기반서비스 이용약관 동의<span>선택</span></label>
                            </div>
                            <p><Link to='/agree' state={{ index : 2}}>자세히보기</Link></p>
                        </li>
                        <li>
                            <div className="clause">
                                <input type="checkbox" id="promotion" name="agree_promotion" onChange={onChangeAgree}/>
                                <label htmlFor="promotion">프로모션 정보 수신 동의<span>선택</span></label>
                            </div>
                            <p><Link to='/agree' state={{ index : 3}}>자세히보기</Link></p>
                        </li>
                        <li>
                            <span className="join_warning_txt">{joinWarningTxt}</span>
                        </li>
                    </ul>
                </div>
                <p className={`join_submit ${joinBtnState}`}><a className="join_submit_btn" onClick={join}>회원가입</a></p>
            </fieldset>
        </form>
    )
}

function FindIdForm(props){
    const find_id_form = document.find_id;
    function id_find(){
        axios({
            method: 'post',
            url: `${process.env.REACT_APP_BACKEND_SERVER}/user/idFindByEmail`,
            data: {
                email: find_id_form.email.value
            }
        }).then((response) => {
            alert(`아이디 : ${response.data}`);
            props.setOverlayMove('right');
        }).catch((error) => {
            alert(error.response.data);
    });
    }
    return(
        <form id="find_id" name="find_id" action="#" method="get" className={props.formFrontIndex == 1 ? "front" : ''}>
            <fieldset>
                <legend>아이디찾기</legend>
                <h3>아이디 찾기</h3>
                <ul className="find_box">
                    <li>                        
                        <p className="find_email login_txt">EMAIL</p>
                        <p><input className="find_pw_empty" type="text" placeholder="가입한 이메일 입력" name="email"  onKeyDown={(e)=>{if(e.keyCode === 13){id_find()}}} /></p>
                    </li>
                </ul>
                <p><span className="find_id_warning_txt"></span></p>
                <p className="find_id"><a href="#" className="find_id_btn" onClick={id_find}>아이디 찾기</a></p>
            </fieldset>
        </form>
    )
}

function FindPwForm(props){
    const [findPwBtnState,setFindPwBtnState] = useState(false);
    const [authNumber,setAuthNumber] = useState(null);
    const pwRegExp = /^[a-zA-z0-9]{8,20}$/;
    const [pwWarningTxt,setPwWarningTxt] = useState('');
    const find_pw_form = document.find_pw;
    function email_auth(e){
        e.preventDefault();
        axios({
            method: 'post',
            url: `${process.env.REACT_APP_BACKEND_SERVER}/user/emailVerify`,
            data: {
                id : find_pw_form.id.value,
                email: find_pw_form.email.value
            }
        }).then((response) => {
            alert('이메일에 인증번호를 보냈습니다.');
            setAuthNumber(response.data);
        }).catch((error) => {
            console.log(error);
            alert(error.response.data.message);
            setAuthNumber(null);
        });
    }
    function auth_number_check(e){
        e.preventDefault();
        if(Number(authNumber) === Number(find_pw_form.authNumber.value)){
            alert('인증완료.');
            setFindPwBtnState(true);
        }else{
            alert('인증에 실패했습니다. 다시 인증요청 해주시길 바랍니다.');
            setAuthNumber(null);
            setFindPwBtnState(false);
            
        }
    }
    function checkValue(RegExp,value,setUseState,message){
        if(!RegExp.test(value)){
            setUseState(message);
        }else{
            setUseState('');
        }
    }

    
    function resetPassword(e){
        e.preventDefault();
        if(findPwBtnState === false){return false}
        axios({
            method: 'post',
            url: process.env.REACT_APP_RESETPASSWORD_SERVER_URL,
            data: {
                id : find_pw_form.id.value,
                newPw : find_pw_form.pw.value
            }
        }).then((response) => {
            alert('비밀번호 변경완료');
            props.setOverlayMove('right');

        }).catch((error) => {
            console.log(error);
            alert(error.response.data);
            setAuthNumber(null);
            setFindPwBtnState(false);
        });
    }

    return(
        <form id="find_pw" name="find_pw" action="#" method="get" className={props.formFrontIndex == 2 ? "front" : ''}>
            <fieldset>
                <legend>비밀번호 재설정</legend>
                <h3>비밀번호 재설정</h3>
                <ul className="find_box">
                    <li>
                        <p className="find_email login_txt">ID</p>                    
                        <p><input className="find_pw_empty" name="id" type="text" placeholder="가입한 아이디 입력"/>
                        </p>
                    </li>
                    <li>
                        <p className="find_email login_txt">Email</p>                    
                        <p><input className="find_pw_empty" name="email" type="text" placeholder="가입한 이메일 입력" onKeyDown={(e)=>{if(e.keyCode === 13){email_auth(e)}}}/>
                        <button type="submit" className="confirm_email" onClick={email_auth}>인증요청</button>
                        </p>
                    </li>
                    <li>
                        <p className="find_email login_txt">인증번호</p>                    
                        <p><input className="find_pw_empty" name="authNumber" type="text" placeholder="인증번호 입력" onKeyDown={(e)=>{if(e.keyCode === 13){auth_number_check(e)}}}/>
                        <button type="submit" className={`confirm_email ${authNumber === null ? "disabled" : ""}`} onClick={auth_number_check}>인증확인</button>
                        </p>
                    </li>
                    <li>
                        <p className="login_txt">Password</p><span className="warning_txt">{pwWarningTxt}</span>
                        <p><input className="join_pw_empty" type="password" placeholder="비밀번호 입력" name="pw"  onChange={(e)=>{checkValue(pwRegExp,e.target.value,setPwWarningTxt,'영문 대소문자와 숫자 8~20자리');if(pwRegExp.test(e.target.value) && document.find_pw.pwck.value !== e.target.value){setPwWarningTxt('비밀번호 확인란을 입력해주세요.')}}} /></p>
                        <p><input className="join_pwck_empty" type="password" placeholder="비밀번호 확인" name="pwck"  onChange={(e)=>{if(document.find_pw.pw.value !== e.target.value){setPwWarningTxt('비밀번호가 일치하지 않습니다.')}else if(document.find_pw.pw.value == e.target.value){setPwWarningTxt('')}}}/></p>
                    </li>
                </ul>
                <p><span className="find_pw_warning_txt"></span></p>
                <p className="find_pw"><a href="#" className={`find_pw_btn ${findPwBtnState === true && pwWarningTxt ==='' ? "" : "disabled"}`} onClick={resetPassword}>비밀번호 재설정</a></p>
            </fieldset>
        </form>
    )
}

export default Login;
